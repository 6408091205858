import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AnalogsState {
  length: number;
}

const initialState: AnalogsState = {
  length: 0,
};

const analogsSlice = createSlice({
  name: 'analogs',
  initialState,
  reducers: {
    setAnalogsLength: (state, { payload }: PayloadAction<number>) => {
      state.length = payload;
    },
  },
});

export const { setAnalogsLength } = analogsSlice.actions;

export default analogsSlice.reducer;
