import React from 'react';
import { Route } from './types';

const MainPage = React.lazy(() => import('../pages/MainPage/MainPage'));
const CategoryPage = React.lazy(() => import('../pages/CategoryPage/CategoryPage'));
const SelectionPage = React.lazy(() => import('../pages/SelectionPage/SelectionPage'));
const MainLayout = React.lazy(() => import('../layouts/MainLayout/MainLayout'));
const SelectionCategory = React.lazy(() => import('../pages/SelectionCategory/SelectionCategory'));
const SelectionCatalogLayout = React.lazy(() => import('../layouts/SelectionCatalogLayout/SelectionCatalogLayout'));
const SelectionProducts = React.lazy(() => import('../pages/SelectionProducts/SelectionProducts'));
const CategoryProducts = React.lazy(() => import('../pages/CategoryProducts/CategoryProducts'));
const ProductPage = React.lazy(() => import('../pages/ProductPage/ProductPage'));
const SearchResultPage = React.lazy(() => import('../pages/SearchResultsPage/SearchResultPage'));
const CreateOrderPage = React.lazy(() => import('../pages/CreateOrderPage/CreateOrderPage'));
const CartPage = React.lazy(() => import('../pages/CartPage/CartPage'));
const UserLayout = React.lazy(() => import('../layouts/UserLayout/UserLayout'));
const MyOrderPage = React.lazy(() => import('../pages/MyOrdersPage/MyOrderPage'));
const OrderHistoryPage = React.lazy(() => import('../pages/OrderHistoryPage/OrderHistoryPage'));
const MyCarsPage = React.lazy(() => import('../pages/MyCarsPage/MyCarsPage'));
const LaximoVinSearchLayout = React.lazy(() => import('../layouts/LaximoVinSearchLayout/LaximoVinSearchLayout'));
const SelectCarPage = React.lazy(() => import('../pages/SelectCarPage/SelectCarPage'));
const LaximoUnitDetailsPage = React.lazy(() => import('../pages/LaximoUnitDetailsPage/LaximoUnitDetailsPage'));
const LaximoIndexPage = React.lazy(() => import('../pages/LaximoIndexPage/LaximoIndexPage'));
const LaximoCatalogPage = React.lazy(() => import('../pages/LaximoCatalogPage/LaximoCatalogPage'));
const LaximoGroupDetailsPage = React.lazy(() => import('../pages/LaximoGroupDetailsPage/LaximoGroupDetailsPage'));
const LaximoGroupAnalogDetailsPage = React.lazy(() => import('../pages/LaximoGroupAnalogDetailsPage/LaximoGroupAnalogDetailsPage'));
const CartPageContent = React.lazy(() => import('../pages/CartPage/CartPageContent'));
const UserSettings = React.lazy(() => import('../pages/UserSettings/UserSettings'));
const OrderPage = React.lazy(() => import('../pages/OrderPage/OrderPage'));
const SuccessfulOrderPage = React.lazy(() => import('../pages/SuccessfullOrderPage/SuccessfulOrderPage'));
const DelayedPage = React.lazy(() => import('../pages/DelayedPage/DelayedPage'));
const ContactsPage = React.lazy(() => import('../pages/ContactsPage/ContactsPage'));
const DelayedPageContent = React.lazy(() => import('../pages/DelayedPage/DelayedPageContent'));
const InfoPage = React.lazy(() => import('../pages/InfoPage/InfoPage'));
const RequisitesPage = React.lazy(() => import('../pages/RequisitesPage/RequisitesPage'));
const ForIndividualsPage = React.lazy(() => import('../pages/ForIndIvidualsPage/ForIndividualsPage'));
const AboutPage = React.lazy(() => import('../pages/AboutPage/AboutPage'));
const HowBuyPage = React.lazy(() => import('../pages/HowBuyPage/HowBuyPage'));
const ForLegalsEntitiesPage = React.lazy(() => import('../pages/ForLegalsEntitiesPage/ForLegalsEntitiesPage'));
const PaymentAndReturnPage = React.lazy(() => import('../pages/PaymentAndReturnPage/PaymentAndReturnPage'));
const ErrorPage = React.lazy(() => import('../pages/ErrorPage/ErrorPage'));

export default [
  {
    path: '/',
    element: MainLayout,
    name: 'Главная',
    children: [
      {
        index: true,
        element: MainPage,
      },
      {
        path: '*',
        element: ErrorPage,
      },
      {
        path: 'payment-and-return',
        element: PaymentAndReturnPage,
      },
      {
        path: 'for-legals',
        element: ForLegalsEntitiesPage,
      },
      {
        path: 'how-buy',
        element: HowBuyPage,
      },
      {
        path: 'about',
        element: AboutPage,
      },
      {
        path: 'info',
        element: InfoPage,
      },
      {
        path: 'info/:id',
        element: InfoPage,
      },
      {
        path: 'contacts',
        element: ContactsPage,
      },
      {
        path: 'requisites',
        element: RequisitesPage,
      },
      {
        path: 'for-individuals',
        element: ForIndividualsPage,
      },
      {
        path: 'selection',
        element: SelectionPage,
        name: 'Подбор',
        children: [
        ],
      },
      {
        path: 'selection/catalog',
        element: SelectionCatalogLayout,
        children: [
          {
            path: 'category/:id',
            element: SelectionCategory,
          },
          {
            path: 'category/:parentId/:id/products',
            element: SelectionProducts,
          },
        ],
      },
      {
        path: 'category/:id',
        element: CategoryPage,
        breadcrumbType: 'category',
        breadcrumbs: true,
      },
      {
        path: 'category/:parentId/:id/products',
        element: CategoryProducts,
        breadcrumbType: 'category',
        breadcrumbs: true,
      },
      {
        path: 'category/:id/products',
        element: CategoryProducts,
        breadcrumbType: 'category',
        breadcrumbs: true,
      },
      {
        path: 'category/:parentId/:categoryId/products/:id',
        element: ProductPage,
        breadcrumbType: 'product',
        breadcrumbs: true,
      },
      {
        path: 'category/:categoryId/products/:id',
        element: ProductPage,
        breadcrumbType: 'product',
        breadcrumbs: true,
      },
      {
        path: 'product/:id',
        element: ProductPage,
      },
      {
        path: 'search',
        element: SearchResultPage,
      },
      {
        path: 'create-order',
        element: CreateOrderPage,
      },
      {
        path: 'success',
        element: SuccessfulOrderPage,
      },
      {
        path: 'cart',
        element: CartPage,
      },
      {
        path: 'delayed',
        element: DelayedPage,
      },
      {
        path: 'user',
        element: UserLayout,
        children: [
          {
            path: 'orders',
            element: MyOrderPage,
          },
          {
            path: 'order/:id',
            element: OrderPage,
          },
          {
            path: 'cart',
            element: CartPageContent,
          },
          {
            path: 'delayed',
            element: DelayedPageContent,
          },
          {
            path: 'cars-list',
            element: MyCarsPage,
          },
          {
            path: 'history-orders',
            element: OrderHistoryPage,
          },
          {
            path: 'settings',
            element: UserSettings,
          },
        ],
      },
      {
        path: 'vin-search/:vin/:vehicleId',
        element: LaximoVinSearchLayout,
        children: [
          {
            index: true,
            element: LaximoIndexPage,
          },
          {
            path: 'catalog/:catalog/:categoryId/:ssd',
            element: LaximoCatalogPage,
          },
          {
            path: 'categories/:catalog/:categoryId/:ssd',
            element: LaximoGroupDetailsPage,
          },
          {
            path: 'analogs/:catalog/:ssd/:name',
            element: LaximoGroupAnalogDetailsPage,
          },
        ],
      },
      {
        path: 'vin-search/:vin',
        element: SelectCarPage,
        children: [
        ],
      },
      {
        path: 'unit-detail/:vin/:vehicleId/:catalog/:categoryId/:unitId/:ssd',
        element: LaximoUnitDetailsPage,
      },
    ],
  },
] as Route[];
